/* Url di base per le varie risorse utilizzate all'interno della dasboard */

export const apiConfig = {
  accounts: '/accounts',
  logout: '/accounts/logout',
  apikeys: '/:projectId/apikeys',
  usersList: '/:projectId/dashboard/users',
  groupslist: '/:projectId/groups',
  aclRules: '/:projectId/aclrules',
  groupmemberships: '/:projectId/groupmemberships',
  roles: '/:projectId/roles',
  plants: '/:projectId/plants',
  plantsList: '/:projectId/dashboard/components/plants',
  aggregatorsList: '/:projectId/aggregators',
  districtsList: '/:projectId/districts',
  assetTypes: '/:projectId/assetTypes',
  assets: '/:projectId/assets',
  nodes: '/:projectId/nodes',
  nodesList: '/:projectId/dashboard/components/nodes',
  nodeTypes: '/:projectId/nodetypes',
  nodeConfig: '/:projectId/',
  // devices: '/:projectId/dashboard/components/devices',
  devices: '/:projectId/devices',
  deviceTypes: '/:projectId/devicetypes',
  orientations: '/:projectId/orientations',
  dashboard: '/:projectId/dashboard',
  forceSummaryRefresh: '/:projectId/dashboard/plants',
  jobs: '/jobs',
  uploadFiles: '/:projectId/imports/measures',
  openstreetmap: 'https://nominatim.openstreetmap.org',
  plantsMap: '/:projectId/dashboard/components/plants/map',
  plantDetails: '/:projectId/dashboard/components/plants',
  logs: '/:projectId/telemetry',
  logsInExcel: '/:projectId/telemetry/export',
  anomaliesList: '/:projectId/anomalies',
  anomaliesConfig: '/:projectId/anomalyconfigurations',
  uploadDatasheet: '/:projectId/uploads/devices',
  uploads: '/:projectId/uploads',
  links: '/:projectId/links',
  publicLinks: '/links',
  reports: '/:projectId/reports',
  feedbacks: '/:projectId/feedbacks',
  analytics: '/:projectId/dashboard/components/analytics',
  plantOverviewV1: '/:projectId/dashboard/components/overview',
  plantOverview: '/:projectId/dashboard/components/overview/v2',
  aggregatorsExports: '/:projectId/exports',
  smartsim: '/:projectId/smartsim',
  smarthomeHeatmap: '/clustering/carpet_settimanatipo'
}
